<template>
  <div class="lds">
    <span class="lds-ring" />
  </div>
</template>

<style scoped lang="scss">

.lds-ring {
  width: 32px;
  height: 32px;
  border: 3px solid var(--main-blue);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
